var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-sidebar",class:[
    {
      expanded:
        !_vm.isVerticalMenuCollapsed ||
        (_vm.isVerticalMenuCollapsed && _vm.isMouseHovered),
    } ],on:{"mouseenter":function($event){return _vm.updateMouseHovered(true)},"mouseleave":function($event){return _vm.updateMouseHovered(false)}}},[_c('div',{staticClass:"sidebar"},[_c('b-link',{staticClass:"navbar-brand mb-2",attrs:{"to":"/"}},[_c('span',{staticClass:"brand-logo"},[_c('b-img',{attrs:{"src":require("@/assets/images/svg/logo.svg"),"alt":"logo"}})],1)]),_c('b-link',{staticClass:"new-btn",attrs:{"to":"/new-case"}},[_c('div',{staticClass:"d-flex align-items-center gap-1"},[_c('div',{staticClass:"icon"},[_c('ElementIcon',{attrs:{"icon":'teethIcon'}})],1),_c('span',[_vm._v(_vm._s(_vm.$t("New Case")))])]),_c('div',{staticClass:"add-icon"},[_c('ElementIcon',{attrs:{"icon":'addIcon'}})],1)]),_c('vue-perfect-scrollbar',{staticClass:"main-menu-content scroll-area",attrs:{"settings":_vm.perfectScrollbarSettings,"tagname":"ul"},on:{"ps-scroll-y":function (evt) {
          _vm.shallShadowBottom = evt.srcElement.scrollTop > 0;
        }}},[_c('vertical-nav-menu-items',{staticClass:"navigation navigation-main",attrs:{"items":_vm.navMenuItems}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }