<template>
  <p class="clearfix mb-0">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      COPYRIGHT  © {{ new Date().getFullYear() }}
      <b-link
        class="ml-25"
        href="https://kombo-medical.de"
        target="_blank"
      >KOMBO medical solutions UG</b-link>
      <span class="d-none d-sm-inline-block">, {{ $t("All rights Reserved") }}</span>
    </span>
    <span class="float-md-left d-block d-md-inline-block mt-25 ml-1">
      | 
      <b-link
        class="ml-1"
        href="https://www.dentaltwin.net/agb"
        target="_blank"
      > {{$t("Terms and Conditions")}}</b-link>
    </span>
    <span class="float-md-left d-block d-md-inline-block mt-25 ml-1">
      | 
      <b-link
        class="ml-1"
        href="https://www.dentaltwin.net/impressum"
        target="_blank"
      >{{$t("Imprint")}}</b-link>
    </span>
    
    <span class="float-md-left d-block d-md-inline-block mt-25 ml-1">
      | 
      <b-link
        class="ml-1"
        href="https://www.dentaltwin.net/datenschutzerkl%C3%A4rung"
        target="_blank"
      >{{$t("Data Privacy")}}</b-link>
    </span>

    <span class="float-md-right d-none d-md-block mt-25"> {{ $t("Made in Germany with") }}
      <feather-icon
        icon="HeartIcon"
        size="14"
        class="text-danger stroke-current"
      />
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
