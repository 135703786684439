<template>
  <div
    class="main-sidebar"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <div class="sidebar">
      <b-link class="navbar-brand mb-2" to="/">
        <span class="brand-logo">
          <b-img
            src="@/assets/images/svg/logo.svg"
            alt="logo"
          />
        </span>
      </b-link>
      <b-link to="/new-case" class="new-btn">
        <!-- <div class="new-bg">
          <ElementIcon v-bind:icon="'newBGIcon'" />
        </div>
        <div class="new-bg-hover">
          <ElementIcon v-bind:icon="'newBGDarkIcon'" />
        </div> -->
          <div class="d-flex align-items-center gap-1">
            <div class="icon"><ElementIcon v-bind:icon="'teethIcon'" /></div>
            <span>{{$t("New Case")}}</span>
          </div>

          <div class="add-icon"><ElementIcon v-bind:icon="'addIcon'" /></div>
      </b-link>
      <!-- main menu content-->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="main-menu-content scroll-area"
        tagname="ul"
        @ps-scroll-y="
          (evt) => {
            shallShadowBottom = evt.srcElement.scrollTop > 0;
          }
        "
      >
        <vertical-nav-menu-items
          :items="navMenuItems"
          class="navigation navigation-main"
        />
      </vue-perfect-scrollbar>
      <!-- /main menu content-->
      <!-- main menu header-->
      <!-- <div class="navbar-header expanded">
        <slot
          name="header"
          :toggleVerticalMenuActive="toggleVerticalMenuActive"
          :toggleCollapsed="toggleCollapsed"
          :collapseTogglerIcon="collapseTogglerIcon"
        >
          <ul class="nav navbar-nav flex-row">
            <li class="nav-item mr-auto">
              <b-link class="navbar-brand" to="/">
                <span class="brand-logo">
                  <b-img :src="appLogoImage" alt="logo" />
                </span>
                <h2 class="brand-text">
                  {{ appName }}
                </h2>
              </b-link>
            </li>
          </ul>
        </slot>
      </div> -->
      <!-- / main menu header-->

      <!-- Shadow -->
      <!-- <div :class="{ 'd-block': shallShadowBottom }" class="shadow-bottom" /> -->
    </div>
  </div>
</template>

<script>
import navMenuItems from "@/navigation/vertical";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { BLink, BImg } from "bootstrap-vue";
import { provide, computed, ref } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import { $themeConfig } from "@themeConfig";
import VerticalNavMenuItems from "./components/vertical-nav-menu-items/VerticalNavMenuItems.vue";
import useVerticalNavMenu from "./useVerticalNavMenu";
import ElementIcon from "@/components/elements/Icon.vue";
export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
    ElementIcon,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props);

    const { skin } = useAppConfig();

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false);

    provide("isMouseHovered", isMouseHovered);

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const collapseTogglerIconFeather = computed(() =>
      collapseTogglerIcon.value === "unpinned" ? "CircleIcon" : "DiscIcon"
    );

    // App Name
    const { appName, appLogoImage } = $themeConfig.app;

    return {
      navMenuItems,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>
